<template>
  <div class="app-container">
    <h2>{{ $t('reviewsBureauDeWit.header') }}</h2>
    <el-link :href="reviewsBureauDeWitURL" target="_blank">
      {{ $t('reviewsBureauDeWit.loginToReviewsBureauDeWit') }}
    </el-link>
  </div>
</template>

<script>
export default {
  computed: {
    reviewsBureauDeWitURL() {
      return this.$store.getters['configuration/value']('reviewsBureauDeWit.URL');
    }
  }
};
</script>
